/**
 * Kameleoon Feature Flag Experiment 'feature key' constants
 */
export const KameleoonExperiments = {
    AddCopyToSaveIconOnItemPage: 'la_-_add_copy_to_save_icon_on_lot_details_page',
    AddSaveCopyToItemCards: 'la_-_add_save_copy_to_item_cards',
    ApprovalsBids100OrLess: 'la_-_approvals_bids__100_or_less',
    BackgroundAutoApproval: 'la_-_background_auto-approval',
    BuyNowSearchPage: 'la_buy_now_nav',
    CompleteRegistrationPrompt: 'la_-_complete_registration_prompt',
    FacebookSocialSignOn: 'la_-_facebook_social_sign-on',
    J52BuyersPremium: 'bp_jasper52',
    NewUserSegment: 'la_ben_norway_rock_star_1',
    PurchaseProtectionBlurb: 'la_purchase_protection_blurb',
    SimplifiedNavMultivariateTest: 'la_-_simplified_nav__multivariate_test_',
} as const;

export type KameleoonExperimentIds = (typeof KameleoonExperiments)[keyof typeof KameleoonExperiments];

export const KameleoonVariants: {
    [key in keyof typeof KameleoonExperiments]: {
        [variant: string]: string;
        Off: 'off';
    };
} = {
    AddCopyToSaveIconOnItemPage: {
        Control: 'on',
        NewSaveButton: 'new_save_button',
        Off: 'off',
    },
    AddSaveCopyToItemCards: {
        Control: 'control',
        Off: 'off',
        SaveCopy: 'save_copy',
    },
    ApprovalsBids100OrLess: {
        AutoApprovalsVariant: '_100_auto-approvals_variant_',
        Control: 'control',
        Off: 'off',
    },
    BackgroundAutoApproval: {
        AutoApprove: 'auto_approve',
        Control: 'control',
        Off: 'off',
    },
    BuyNowSearchPage: {
        Control: 'control',
        Off: 'off',
        On: 'buy_now',
    },
    CompleteRegistrationPrompt: {
        Control: 'control',
        MyAccountIconBadge: 'my_account_icon_badge',
        Off: 'off',
    },
    FacebookSocialSignOn: {
        Control: 'control',
        FacebookSignOn: 'facebook_sign-on',
        Off: 'off',
    },
    J52BuyersPremium: {
        Control: 'control',
        Off: 'off',
        On: 'on',
    },
    NewUserSegment: {
        Control: 'control',
        Off: 'off',
        On: 'on',
    },
    PurchaseProtectionBlurb: {
        Control: 'control',
        Off: 'off',
        On: 'on',
    },
    SimplifiedNavMultivariateTest: {
        Control: 'control',
        Off: 'off',
        VariantAHighContrast: 'variant_a__high_contrast_',
        VariantBSearchWithBorder: 'variant_b__search_with_border_',
    },
} as const;

export type KameleoonExperimentsVariantIds = (typeof KameleoonVariants)[keyof typeof KameleoonVariants];
